import React, { useState } from "react";
import { Link } from "gatsby";
import { IntlContextConsumer } from "gatsby-plugin-intl";

import AgmContainer from "../../../components/agmContainer";

const AnnualMeetingMaterials = ({ data = [] }) => {
  const [filter, setFilter] = useState(new Date().getFullYear().toString() || "2022");

  const generateDates = () => {
    const allDates = [];

    for (const document of data) {
      if (allDates.includes(document.year)) {
        continue;
      }

      allDates.push(document.year);
    }

    return allDates.sort().reverse();
  };

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <AgmContainer
          title={currentLocale === "fr" ? "SÉLECTIONNER L'ANNÉE :" : "SELECT YEAR:"}
          yearsList={generateDates()}
          setFilter={setFilter}
          filter={filter}
        >
          <ul className="mb-8 lg:mb-4">
            {data
              .filter(({ year }) => {
                return !filter || filter === year;
              })
              .map(({ link, title }) => (
                <li className="bg-grow-blue rounded-lg py-4 px-4 hover:py-8 flex mb-2 items-start lg:items-center">
                  <span className="hidden md:block font-xs tracking-wider w-1/3 md:1/4 lg:w-1/6 pr-4">
                    {currentLocale === "fr" ? "VOIR" : "VIEW:"}
                  </span>
                  <span className="lg:items-center flex-1 flex flex-col lg:flex-row lg:pl-8 truncate">
                    <span className="flex-1 flex items-center lg:order-2 lg:pl-16">
                      <Link
                        to={link}
                        target="_blank"
                        className="text-primary underline uppercase font-xs tracking-wider truncate"
                      >
                        {title}
                      </Link>
                    </span>
                  </span>
                </li>
              ))}
          </ul>
        </AgmContainer>
      )}
    </IntlContextConsumer>
  );
};

export default AnnualMeetingMaterials;
