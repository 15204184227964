import React, { useState } from "react";

import "./index.scss";

const IconHeader = () => {
  return (
    <svg className="yearsTriangle" xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 24 24">
      <path d="M24 22h-24l12-20z" fill="#606060" />
    </svg>
  );
};

const LinkItem = ({ filter, onClick }) => {
  return (
    <button onClick={onClick} className="flex items-center w-full h-31px">
      <h4 className="mr-2">{filter}</h4>
      <IconHeader />
    </button>
  );
};

const DropDownItem = ({ yearsList, filter, setFilter }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const filterYears = yearsList.filter((year) => year !== filter);
  return (
    <div className={`relative px-2 border ${menuIsOpen ? "containerShadow border-light-grey2" : "border-white"}`}>
      <LinkItem
        onClick={() => {
          setMenuIsOpen(true);
        }}
        filter={filter}
      />
      <ul
        className={`absolute bg-white ${
          menuIsOpen ? "dropdownMenu visible opacity-1 border-l border-r border-b" : "invisible opacity-0"
        }`}
      >
        {filterYears.map((text) => (
          <li
            className="px-2 cursor-pointer hover:bg-primary"
            onClick={() => {
              setFilter(text);
              setMenuIsOpen(false);
            }}
          >
            <h4>{text}</h4>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropDownItem;
