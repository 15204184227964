import React from "react";
import DropDownItem from "../yearsDropdown";

const AgmContainer = ({ title, yearsList, children, filter, setFilter }) => {
  return (
    <div className="py-8 lg:py-16">
      <div className="global-x-spacing px-0 lg:px-16 max-w-78 mx-auto">
        <div className="flex border-b border-secondary pb-4 mb-4 lg:mb-8">
          <h4 className="text-secondary uppercase mr-8">{title}</h4>
          <DropDownItem yearsList={yearsList} filter={filter} setFilter={setFilter} />
        </div>
        <div className="md:max-w-185">{children}</div>
      </div>
    </div>
  );
};

export default AgmContainer;
